import React from 'react';
import SignUpSheet from './components/SignUpSheet/SignUpSheet';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
    return (
        <SignUpSheet />
    );
}

export default App;
